<template>
  <div class="product-tag-item-price-form">
    <b-row>
      <b-col sm="6" lg="3" :class="{'error': $v.field.tagItem.value.$invalid && $v.field.tagItem.value.$dirty}">
        <b-form-input
          @change="updateModel"
          v-model="$v.field.tagItem.value.$model"
          :placeholder="$t('description')"
          max="255"
          required
        />
        <div v-if="$v.field.tagItem.value.$invalid && $v.field.tagItem.value.$dirty" class="errors">
          <span v-if="!$v.field.tagItem.value.required">{{ $t('tagItemdescriptionRequired') }}</span>
        </div>
      </b-col>
      <b-col sm="6" lg="3" :class="{'error': $v.field.price.$invalid && $v.field.price.$dirty}">
        <b-row>
          <b-col
            sm="8"
            style="padding-left: 0; padding-right: 10px;"
          >
            <b-form-input
              type="number"
              @change="updateModel"
              v-model="$v.field.price.$model"
              :placeholder="$t('price')"
              required
              max="255"
            />
          </b-col>
          <b-col sm="4" style="padding-left: 0; padding-right: 0; padding-top: 8px;">
            {{ finalPrice }}
          </b-col>
        </b-row>
        <div v-if="$v.field.price.$invalid && $v.field.price.$dirty" class="errors">
          <span v-if="!$v.field.price.required">{{ $t('priceRequired') }}.</span>
          <span v-if="!$v.field.price.minValue">{{ $t('priceMinValue') }}.</span>
        </div>
      </b-col>
      <b-col sm="6" lg="3" class="checkbox-container" :class="{'error': $v.discount.$invalid && $v.discount.$dirty}">
        <b-row>
          <b-col sm="2" lg="1">
            <b-checkbox
              @change="updateDiscountShowOnMainModel"
              v-model="showOnMain"
            />
          </b-col>
          <b-col sm="6" lg="6" style="padding: 0 5px">
            <b-form-input
              type="number"
              v-model="$v.discount.$model"
              @change="updateDiscountModel"
              :placeholder="$t('discount')"
              required
              max="255"
            />
          </b-col>
          <b-col sm="3" lg="4" style="padding: 0 5px">
            <b-form-select
              v-if="$v.discount.$model > 0"
              v-model="$v.discountTypeValue.$model"
              @change="updateDiscountLevelModel"
              :options="discountOptions"
            ></b-form-select>
          </b-col>
        </b-row>
        <div v-if="$v.discount.$invalid && $v.discount.$dirty" class="errors">
          <span v-if="!$v.discount.minValue">{{ $t('discountMinValue') }}</span>
        </div>
      </b-col>
      <b-col sm="6" lg="3">
        <b-form-input
          @change="updateModel"
          v-model="field.sku"
          :placeholder="$t('article')"
          max="255"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import {numeric, minValue, required} from "vuelidate/lib/validators";

  export default {
    props: ['value', 'index'],
    validations: {
      discount: {
        numeric,
        minValue: minValue(0),
      },
      discountTypeValue: {
        required,
      },
      field: {
        tagItem: {
          value: {
            required,
          }
        },
        price: {
          required,
          numeric,
          minValue: minValue(0),
        },
      }
    },
    data () {
      return {
        discountOptions: [
          {value: 1, text: this.$t('discountValue')},
          {value: 2, text: this.$t('percentValue')},
        ],
        discount: 0,
        discountId: 0,
        discountTypeValue: 1,
        levelId: 0,
        showOnMain: false,
        field: {
          price: null,
          sku: "",
          tagItem: {
            value: "",
            shortDescription: "",
            description: "",
            tag: null,
            status: 1,
          },
        },
      }
    },
    computed: {
      finalPrice: function () {
        if (this.field.price) {
          let price = parseFloat(this.field.price);
          const discount = parseFloat(this.discount);
          const discountType = parseInt(this.discountTypeValue);
          const discountValue = discountType === 2 ? price * discount / 100 : discount;
          if (discountValue > 0) {
            if (discountValue >= price) {
              return 0;
            }
            return price - discountValue;
          }
          return price;
        }
        return '-';
      },
    },
    async beforeMount() {
      if (this.value) {
        let field = this.value;
        if (this.value.discounts && this.value.discounts.length > 0) {
          this.discountId = this.value.discounts[0].id;
          this.showOnMain = this.value.discounts[0].showOnMain;
          if (this.value.discounts[0].levels.length > 0) {
            this.levelId = this.value.discounts[0].levels[0].id;
            this.discount = this.value.discounts[0].levels[0].value;
            this.discountTypeValue = this.value.discounts[0].levels[0].type;
          }
        }
        this.field = field;
      }
    },
    methods: {
      touch() {
        this.$v && this.$v.$touch();
      },
      updateModel() {
        if (this.field.tagItem.value !== this.field.tagItem.description) {
          this.field.tagItem.description = this.field.tagItem.value;
        }
        if (this.field.tagItem.value !== this.field.tagItem.shortDescription) {
          this.field.tagItem.shortDescription = this.field.tagItem.value;
        }
        const value = Object.assign({}, this.value, this.field);
        if (value.discounts && value.discounts.length > 0 && value.discounts[0].levels.length > 0) {
          value.discounts[0].levels[0].type = this.discountTypeValue;
          value.discounts[0].levels[0].value = this.discount;
          if (this.levelId) {
            value.discounts[0].levels[0].id = this.levelId;
          }
        }
        this.$emit('updateModel', {
          index: this.index,
          data: value,
        });
      },
      updateDiscountShowOnMainModel(checked) {
        const discounts = this.value.discounts;
        if (discounts && discounts.length > 0) {
          discounts[0].showOnMain = checked;
        }
        this.value.discounts = discounts;
        this.updateModel();
      },
      updateDiscountLevelModel(value) {
        this.discountTypeValue = value;
        this.updateDiscountModel(this.discount);
      },
      updateDiscountModel(value) {
        let discount = 0;
        if (value && !isNaN(value)) {
          discount = parseFloat(value);
        }
        if (discount) {
          const level = {
            level: 0,
            value: discount,
            type: this.discountTypeValue,
            status: 1,
          };
          if (this.levelId) {
            level.id = this.levelId;
          }
          this.value.discounts = [{
            discountType: {
              id: 1
            },
            showOnMain: this.showOnMain,
            levels: [level]
          }];
        } else {
          this.value.discounts = [{
            discountType: {
              id: 1
            },
            showOnMain: this.showOnMain,
            levels: [],
          }];
        }

        if (this.discountId) {
          this.value.discounts[0].id = this.discountId;
        }
        this.updateModel();
      }
    }
  }
</script>

<style lang="scss">
  .product-tag-item-price-form {
    width: 100%;
    margin-bottom: 5px;
  }
  .btn-price-tag-item-danger {
    margin-top: 0px;
    vertical-align: bottom;
  }
  .checkbox-container {
    label {
      display: block;
    }
    .custom-checkbox {
      padding-top: 5px;
      display: inline-block;
    }
    & > input {
      display: inline-block;
      width: 80%;
    }
  }
</style>
