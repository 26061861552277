
import i18n from "@/plugins/i18n";

export const productPriceTagItemsValidator = (value) => {
  const errors = [];
  if (value) {
    if (value.length === 0) {
      errors.push(i18n.t('TagItemPricesIsMissing'));
    }
    const error = value.some(priceTagItem => {
      if (!priceTagItem.price || isNaN(priceTagItem.price)) {
        return true;
      }
      if (!priceTagItem.tagItem.value) {
        return true;
      }
      if (
        priceTagItem.discounts && 
        priceTagItem.discounts[0] &&
        priceTagItem.discounts[0].levels &&
        priceTagItem.discounts[0].levels[0]
      ) {
        const level = priceTagItem.discounts[0].levels[0];
        if (isNaN(level.value) || level.value < 0) {
          return true;
        }
      }
      return false;
    })
    if (error) {
      errors.push(i18n.t('TagItemPricesError'));
    }
    return errors;
  } else {
    errors.push(i18n.t('TagItemPricesIsMissing'));
  }
  return errors;
};
