<template>
  <div>
    <b-row>
      <b-col lg="4" md="6" sm="12">
        <multiselect
          v-model="filterTag"
          label="name"
          track-by="id"
          open-direction="bottom"
          noResult="''"
          noOptions="''"
          :placeholder="'Выберите фильтр'"
          :options="selectableFiltersList"
          :multiple="false"
          :searchable="true"
          :internal-search="true"
          :clear-on-select="true"
          :close-on-select="true"
          :show-no-results="false"
          :hide-selected="false"
          :disabled="disabled"
        />
      </b-col>
      <b-col lg="4" md="6" sm="12">
        <multiselect
          v-model="items"
          label="value"
          track-by="id"
          open-direction="bottom"
          noResult="''"
          noOptions="''"
          group-values="items"
          group-label="tagItem"
          :placeholder="'Выберите значение'"
          :options="filtersItemsList"
          :multiple="true"
          :searchable="true"
          :internal-search="true"
          :clear-on-select="true"
          :close-on-select="true"
          :show-no-results="false"
          :hide-selected="false"
          :loading="isLoading"
          :disabled="disabled"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { APIService } from '../../../../services/api';

export default {
  name: 'ProductFilter',
  props: {
    filterData: {
      type: Object,
      default: () => {},
    },
    allFiltersList: {
      type: Array,
      default: () => [],
    },
    selectableFiltersList: {
      type: Array,
      default: () => [],
    },
    selectedCategoriesIds: {
      type: Array,
      default: () => [],
    },
    dataIndex: {
      type: Number,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filterTag: null,
      isLoading: false,
      filtersItemsList: [],
      items: [],
    }
  },
  computed: {
    filtersList() {
      if (this.filterTag) {
        return [this.filterTag].concat(this.allFiltersList);
      }
      return this.allFiltersList;
    },
  },
  watch: {
    filterTag: {
      async handler(val) {
        if (val && val.id) {
          this.fireUpdateEvent(val, []);
          try {
            this.isLoading = true;
            const filter = this.allFiltersList.find((filter) => filter.id === val.id);
            const filterItems = [];
            for (const filterRelation of filter.filterRelations) {
              const itemsPart = await APIService.get(`filter-tag-item-relation/${filterRelation.id}`);
              let tagItemName = itemsPart.tagItem.value;
              if (itemsPart.tagItem.parent) {
                tagItemName = `${itemsPart.tagItem.parent.value} - ${tagItemName}`;
              }
              filterItems.push({
                tagItem: tagItemName,
                items: itemsPart.relatedTagItemsRelations.map(relation => relation.tagItem),
              });
            }
            this.filtersItemsList = filterItems;
          } catch (e) {
            console.error(e);
          } finally {
            this.isLoading = false;
          }
        }
      },
      deep: true,
    },
    items: {
      handler: function (val) {
        this.fireUpdateEvent(this.filterTag, val);
      },
      deep: true,
    },
  },
  methods: {
    fireUpdateEvent (tag, items = []) {
      const updatedValue = {
        tag,
        items,
      };
      this.$emit('updateModel', {
        index: this.dataIndex,
        updatedPart: updatedValue,
      });
    },
  },
  async beforeMount() {
    this.filterTag = this.filterData.tag;
    this.items = this.filterData.items;
  },
}
</script>
