<template>
    <div v-if="value">
        <b-row>
            <b-col
                v-if="value.length > 0"
                sm="9"
                md="10"
                lg="10"
            >
                <b-row>
                    <b-col sm="6" lg="3">
                        <label>{{ $t('description') }}</label>
                    </b-col>
                    <b-col sm="6" lg="3">
                        <label>{{ $t('price') }}</label>
                    </b-col>
                    <b-col sm="6" lg="3">
                        <label>{{ $t('discount') }}</label>
                    </b-col>
                    <b-col sm="6" lg="3">
                        <label>{{ $t('article') }}</label>
                    </b-col>
                </b-row>
            </b-col>
            <b-col
                sm="3"
                md="2"
                lg="2"
            >
                &nbsp;
            </b-col>
        </b-row>

        <b-row v-for="(priceTag, index) in value" :key="priceTag.id">
            <b-col
                sm="9"
                md="10"
                lg="10"
            >
                <price-tag-item
                    ref="price-tag-item"
                    :value="priceTag"
                    :index="index"
                    @updateModel="updateModel"
                />
            </b-col>
            <b-col
                sm="3"
                md="2"
                lg="2"
            >
                <b-button
                    block
                    variant="danger"
                    @click="removeItem(index)"
                    :disabled="value.length < 2"
                >
                    {{ $t("removeProductPriceTagItem") }}
                </b-button>
            </b-col>
        </b-row>

        <b-col
            sm="4"
            md="3"
            lg="3"
            offset-sm="8"
            offset-md="9"
            offset-lg="9"
            class="no-padding"
            style="margin-top: 10px"
        >
            <b-button
                block
                variant="primary"
                @click="addItem"
            >
                {{ $t("addProductPriceTagItem") }}
            </b-button>
        </b-col>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { abstractField } from 'vue-form-generator';
    import { productPricesTagItemEventBus } from './productPricesTagItemEventBus';
    import PriceTagItem from './FieldPriceTagItem';
    import PriceTagItemTranslated from './FieldPriceTagItemTranslated';

    export default {
        mixins: [abstractField],
        components: {
            PriceTagItem,
            PriceTagItemTranslated,
        },
        created () {
            productPricesTagItemEventBus.$on('updated-translations', (eventData) => {
                this.updateTranslationsValue(eventData);
            });
        },
        methods: {
            addItem() {
                const newItem = JSON.parse(JSON.stringify(this.schema.default[0]));
                this.value.push(newItem);
                productPricesTagItemEventBus.$emit('added-item');
            },
            validate() {
                this.clearValidationErrors();
                const value = this.value;
                const validation = this.schema.validator[0];
                for (const ref of this.$refs['price-tag-item']) {
                    ref.touch();
                }
                return validation(value);
            },
            updateModel(eventData) {
                const { index, data } = eventData;
                Vue.set(this.value, index, data);
                productPricesTagItemEventBus.$emit('updated-item', eventData);
            },
            updateTranslationsValue(eventData) {
                const { index, translations } = eventData;
                const priceTagItemData = this.value[index];
                priceTagItemData.tagItem.translations = translations;
                Vue.set(this.value, index, priceTagItemData);
            },
            removeItem(index) {
                this.value.splice(index, 1);
                productPricesTagItemEventBus.$emit('remove-item', {
                    index,
                });
            }
        }
    };
</script>
