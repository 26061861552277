
import i18n from "@/plugins/i18n";

export const productTagItemsValidator = (value) => {
  const errors = [];
  const producers = value.filter(v => v && v.tag && v.tag.token === 'producer');
  const categories = value.filter(v => v && v.tag && v.tag.token === 'category');
  if (producers.length === 0 || categories.length === 0) {
    errors.push(i18n.t('TagItemError'));
  }
  const filterError = value.filter(v => v && v.tag && !['producer', 'category'].includes(v.tag.token)).some(
    v => !v.tag.id
  );
  if (filterError) {
    errors.push(i18n.t('TagItemFilterError'));
  }
  return errors;
};
