<template>
  <div>
    <div :class="{'categories-error': (categoriesTouched && categories.length === 0)}">
      <label :class="{'error': (categoriesTouched && categories.length === 0)}">{{ $t('categories') }}</label>
      <b-form-group>
        <b-form-checkbox-group :disabled="disabled" v-model="categories">
          <b-card v-for="item in allCategories" no-body class="mb-1" :key="item.header">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle="'accordion-' + item.header">
              <span>
                {{ item.header }} <span v-if="categorySelectedCount(item.items) > 0">({{ categorySelectedCount(item.items) }})</span>
              </span>
              </b-button>
            </b-card-header>
            <b-collapse :id="'accordion-' + item.header" :accordion="'accordion-' + item.header">
              <b-card-body>
                <b-card-text>
                  <b-row>
                    <b-col
                      v-for="subitem in item.items"
                      :key="subitem.header"
                      sm="12"
                      md="4"
                      lg="3"
                    >
                      <b-form-checkbox :value="subitem">
                        {{ getLabel(subitem) }}
                      </b-form-checkbox>
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
        </b-form-checkbox-group>
      </b-form-group>
    </div>
    <div v-if="categoriesTouched && categories.length === 0" class="errors help-block">
      <span class="custom-error">{{ $t('categoriesAreNotSelected') }}</span>
    </div>
  </div>
</template>

<script>
import { APIService } from '../../../../services/api';

export default {
  name: 'ProductCategory',
  props: {
    filterData: {
      type: Object,
      default: () => {},
    },
    dataIndex: {
      type: Number,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      categories: [],
      categoryTag: null,
      categoriesTouched: false,
      allCategories: [],
    }
  },
  async beforeMount() {
    try {
      this.isLoading = true;
      await this.setCategoryTag();
      const allCategories = await APIService.get(`tag-item?sort=orderKey,ASC&sort=id,DESC&sort=children.orderKey,ASC&join=tag&join=children&join=children.tag&join=parent&filter=parent.id||$isnull&filter=tag.id||eq||${this.categoryTag.id}`);
      this.allCategories = allCategories.map(tagItem => {
        delete tagItem.productCounts;
        const items = tagItem.children.map(child => {
          child.tag = this.categoryTag;
          delete child.productCounts;
          return child;
        });
        return {
          header: tagItem.value,
          items
        }
      });
      this.categories = this.filterData.items;
      this.isLoading = false;
    } catch(e) {
      console.error(e);
    }
  },
  methods: {
    getLabel (item) {
      return item['value'] ? item['value'] : '';
    },
    getValue (item) {
      const existingElement = this.value ? this.value.filter(singleValue => singleValue.relatedTagItem.id === item.id) : [];
      const id = existingElement.length > 0 ? existingElement[0].id : null;
      const orderKey = existingElement.length > 0 ? existingElement[0].orderKey : null;
      return {
        id: id,
        orderKey: orderKey,
        relatedTagItem: item,
      };
    },
    markAsCategoriesDirty () {
      this.categoriesTouched = true;
    },
    categorySelectedCount (categorySelected) {
      return categorySelected.filter(category => this.categories.some(cat => cat.id === category.id)).length;
    },
    async setCategoryTag() {
      if (this.filterData.tag) {
        this.categoryTag = this.filterData.tag;
      }
      if (!this.categoryTag) {
        const [categoryTag] = await APIService.get('tag?filter=token||eq||category');
        this.categoryTag = categoryTag;
      }
    },
  },
  watch: {
    categories: {
      handler: function (val) {
        const updatedValue = {
          tag: this.categoryTag,
          items: val,
        };
        this.$emit('updateModel', {
          index: this.dataIndex,
          updatedPart: updatedValue,
        });
      },
      deep: true,
    },
  },
}
</script>
