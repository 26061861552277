import { validators } from "vue-form-generator";
import i18n from "@/plugins/i18n";

export const schema = {
  fields: [
    {
      type: "imageUpload",
      label: "images",
      model: "images",
      required: false,
      placeholder: "images",
      imageType: "Product",
      suffix: "thumb",
    },
    {
      type: "input",
      inputType: "text",
      label: "name",
      model: "name",
      required: true,
      placeholder: "name",
      translation: true,
      max: 255,
      validator: [
        validators.string.locale({
          fieldIsRequired: i18n.t("valueRequired"),
          textTooBig: i18n.t("valueMustBeLess{1}")
        }),
      ]
    },
  ]
};
