<template>
  <div class="product-tag-items">
    <div v-for="(filter, index) in mappedFilters" :key="filter.id">
      <b-row v-if="['producer', 'category'].includes(filter.tag.token)">
        <b-col xs="12">
          <product-producer
            v-if="filter.tag.token === 'producer'"
            :filter-data="filter"
            :data-index="index"
            :disabled="disabled"
            @updateModel="updateModel"
            style="margin-bottom: 10px;"
          />
          <product-category
            v-if="filter.tag.token === 'category'"
            :filter-data="filter"
            :data-index="index"
            :disabled="disabled"
            @updateModel="updateModel"
          />
        </b-col>
      </b-row>
    </div>
    <div v-if="allAvailableFilters.length > 0">
      <div v-for="(filter, index) in mappedFilters" :key="filter.id">
        <b-row v-if="!['producer', 'category'].includes(filter.tag.token)" style="margin-bottom: 6px">
          <b-col lg="10" md="9" sm="8" xs="12">
            <product-filter
              :filter-data="filter"
              :data-index="index"
              :disabled="disabled"
              :all-filters-list="allAvailableFilters"
              :selectable-filters-list="availableForSelectionFilters"
              @updateModel="updateModel"
            />
          </b-col>
          <b-col lg="2" md="3" sm="4" xs="12">
            <b-button
              block
              variant="danger"
              :disabled="disabled"
              @click="deleteFilter(index)"
            >
              {{ $t('deleteFilter') }}
            </b-button>
          </b-col>
        </b-row>
      </div>
      <div style="margin-top: 8px">
        <b-button
          variant="primary"
          :disabled="disabled"
          @click="addFilter"
        >
          {{ $t('addFilter') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';
  import Multiselect from 'vue-multiselect';
  import {abstractField} from 'vue-form-generator';
  import ProductProducer from './productTagItems/productProducer.vue';
  import ProductCategory from './productTagItems/productCategory.vue';
  import ProductFilter from './productTagItems/productFilter.vue';
  import { APIService } from '../../../services/api';

  export default {
    mixins: [abstractField],
    components: {
      Multiselect,
      ProductProducer,
      ProductCategory,
      ProductFilter,
    },
    props: {
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        mappedFilters: [],
        isLoading: false,
        allAvailableFilters: [],
        selectedCategoriesIds: [],
      }
    },
    async beforeMount() {
      if (this.value) {
        this.mappedFilters = this.value.reduce((acc, item) => {
          const existingItem = acc.find(filter => filter.tag.token === item.tag.token);
          if (existingItem) {
            existingItem.items.push(item);
          } else {
            acc.push({
              tag: item.tag,
              items: [item]
            });
          }
          return acc;
        }, []);
      }
      this.isLoading = true;
      await this.fillRequiredData();
      this.setSelectedCategoriesIds();
    },
    watch: {
      selectedCategoriesIds: {
        async handler(val) {
          if (val.length > 0) {
            try {
              const categories = await APIService.get(`tag-item?filter=id||in||${val.join(',')}&join=filterRelations&join=filterRelations.filter`);
              const filters = categories.reduce((acc, category) => {
                const categoryFilters = category.filterRelations || [];
                categoryFilters.forEach(filterRelation => {
                  const filter = filterRelation.filter;
                  filter.filterRelations = [{
                    id: filterRelation.id,
                  }];
                  const existingFilter = acc.find(f => f.id === filter.id);
                  if (!existingFilter) {
                    acc.push(filter);
                  } else {
                    existingFilter.filterRelations.push({
                      id: filterRelation.id,
                    });
                  }
                });
                return acc;
              }, [])
              .filter(filter => !['category', 'producer'].includes(filter.token))
              .sort((a, b) => a.name.localeCompare(b.name));

              this.allAvailableFilters = filters;
            } catch (error) {
              console.error(error);
            }
          } else {
            this.allAvailableFilters = [];
          }
        },
        deep: true,
      },
    },
    computed: {
      availableForSelectionFilters() {
        return this.allAvailableFilters.filter(
          tag => !this.mappedFilters.some(filter => filter.tag.id === tag.id)
        );
      },
    },
    methods: {
      updateModel(eventData) {
        const { index, updatedPart } = eventData;
        Vue.set(this.mappedFilters, index, updatedPart);
        this.setSelectedCategoriesIds();
        this.updateValueFromMappedFilters();
      },
      async fillRequiredData() {
        const requiredFilters = ['producer', 'category'];
        for (const filterToken of requiredFilters) {
          const filterData = this.mappedFilters.find(filter => filter.tag.token === filterToken);
          if (!filterData) {
            const [tag] = await APIService.get(`tag?filter=token||eq||${filterToken}`);
            if (tag) {
              this.mappedFilters.push({
                tag,
                items: [],
              });
            }
          }
        }
      },
      addFilter() {
        this.mappedFilters.push({
          tag: {},
          items: [],
        });
      },
      deleteFilter(index) {
        this.mappedFilters.splice(index, 1);
        this.updateValueFromMappedFilters();
      },
      updateValueFromMappedFilters() {
        this.value = this.mappedFilters.reduce((acc, filter) => {
          acc.push(...filter.items);
          return acc;
        }, []);
      },
      setSelectedCategoriesIds() {
        const categoriesData = this.mappedFilters.find(filter => filter.tag.token === 'category');
        if (!categoriesData) {
          this.selectedCategoriesIds = [];
        }
        const categoriesIds = categoriesData.items.map(item => item.id) || [];
        if (JSON.stringify(categoriesIds) !== JSON.stringify(this.selectedCategoriesIds)) {
          this.selectedCategoriesIds = categoriesIds;
        }
      },
      validate() {
        this.clearValidationErrors();
        if (!this.schema.disabled) {
          const value = this.value;
          const validation = this.schema.validator[0];
          return validation(value);
        }
        return [];
      },
    }
  }
</script>

<style lang="scss">
  .product-tag-items {
    .btn-secondary, .btn-secondary:focus, .btn-secondary:active {
      background: transparent !important;
      border: none !important;
      text-align: left !important;
      box-shadow: none !important;
    }
    .categories-error {
      .card-header {
        border: 1px solid red;
        span {
          color: red;
        }
      }
    }
    .multiselect-error {
      .multiselect__tags {
        border: 1px solid red;
      }
    }
    .multiselect{
      font-size: 14px;
      line-height: 14px;
      .multiselect__tags, .multiselect__single {
        font-size: 14px;
      }
      .multiselect__placeholder{
        font-size: 14px;
      }
      input {
        font-size: 14px;
      }
    }
  }
</style>

