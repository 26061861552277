<template>
  <div class="product-tag-item-price-form">
    <b-row>
      <b-col sm="6" lg="3" :class="{'error': $v.translation.$invalid && $v.translation.$dirty}">
        <b-form-input
         @change="updateTranslations" 
         v-model="$v.translation.$model" 
         :placeholder="$t('description')"
         max="255"
         required
        />
        <div v-if="$v.translation.$invalid && $v.translation.$dirty" class="errors">
          <span v-if="!$v.translation.required">{{ $t('tagItemdescriptionRequired') }}</span>
        </div>
      </b-col>
      <b-col sm="6" lg="3">
        <b-row>
          <b-col
            sm="8" 
            style="padding-left: 0; padding-right: 10px;"
          >
            <b-form-input
              type="number"
              :value="field.price"
              :placeholder="$t('price')"
              :disabled="true"
              max="255"
              required
            />
          </b-col>
          <b-col sm="4" style="padding-left: 0; padding-right: 0; padding-top: 8px;">
            {{ finalPrice }}
          </b-col>
        </b-row>
      </b-col>
      <b-col sm="6" lg="3" class="checkbox-container">
        <b-checkbox 
          v-model="showOnMain"
          :disabled="true"
        />&nbsp;
        <b-form-input
          v-model="discount"
          :placeholder="$t('discount')"
          type="number"
          :disabled="true"
          max="255"
          required

        />
      </b-col>
      <b-col sm="6" lg="3">
        <b-form-input
          :placeholder="$t('article')"
          v-model="field.sku"
          :disabled="true"
          max="255"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import {required} from "vuelidate/lib/validators";

  export default {
    props: ['value', 'index'],
    validations: {
      translation: {
        required,
      }
    },
    data () {
      return {
        parentTag: null,
        discount: 0,
        discountId: 0,
        levelId: 0,
        showOnMain: false,
        translation: "",
        field: {
          price: null,
          sku: "",
          tagItem: {
            value: "",
            tag: null,
            status: 1,
          },
        },
      }
    },
    computed: {
      finalPrice: function () {
        if (this.field.price) {
          let price = parseFloat(this.field.price);
          const discount = parseFloat(this.discount);
          if (discount > 0) {
            if (discount >= price) {
              return 0;
            }
            return price - discount;
          }
          return price;
        }
        return '-';
      },
    },
    beforeMount() {
      this.mapValueToField(this.value);
    },
    beforeUpdate() {
      this.mapValueToField(this.value);
    },
    methods: {
      touch() {
        this.$v && this.$v.$touch();
      },
      mapValueToField(value) {
        if (value) {
          let field = value;
          if (this.value.discounts && value.discounts.length > 0) {
            this.discountId = value.discounts[0].id;
            this.showOnMain = value.discounts[0].showOnMain;
            if (this.value.discounts[0].levels.length > 0) {
              this.levelId = value.discounts[0].levels[0].id;
              this.discount = value.discounts[0].levels[0].value;
            }
          }
          if (value && value.tagItem) {
            if (value && value.tagItem && !value.tagItem.translations) {
              value.tagItem.translations = [{
                lang: "ua",
                field: "value",
                translation: value.tagItem.value
              }];
            }
            const translation = value.tagItem.translations.find(t => t.field === 'value' && t.lang === 'ua')
            if (!this.translation) {
              this.translation = translation.translation;
            }
          }
          this.field = field;
        }
      },
      updateTranslations() {
        const translations = this.value.tagItem.translations.map(f => {
          if (f.field === 'value' && f.lang === 'ua') {
            f.translation = this.translation;
          }
          return f;
        });
        this.$emit('updateTranslations', {
          index: this.index,
          translations,
        });
      },
    }
  }
</script>

<style lang="scss">
  .product-tag-item-price-form {
    width: 100%;
    margin-bottom: 5px;
  }
  .btn-price-tag-item-danger {
    margin-top: 0px;
    vertical-align: bottom;
  }
  .checkbox-container {
    label {
      display: block;
    }
    .custom-checkbox {
      padding-top: 5px;
      display: inline-block;
    }
    & > input {
      display: inline-block;
      width: 80%;
    }
  }
</style>
