import { validators } from "vue-form-generator";
import i18n from "@/plugins/i18n";

export const schema = {
  fields: [
    {
      type: "editor",
      label: "shortDescription",
      model: "shortDescription",
      required: true,
      placeholder: "shortDescription",
      validator: [
        validators.string.locale({
          fieldIsRequired: i18n.t("shortDescription"),
        }),
      ]
    },
    {
      type: "editor",
      label: "description",
      model: "description",
      required: true,
      placeholder: "description",
      validator: [
        validators.string.locale({
          fieldIsRequired: i18n.t("description"),
        }),
      ]
    },
  ]
};
