<template>
  <div>
    <div>
      <label :class="producerTouched && !producer">{{ $t('producers') }}</label>
      <multiselect
        :class="{'multiselect-error': (producerTouched && !producer)}"
        v-model="producer"
        label="value"
        track-by="id"
        open-direction="bottom"
        noResult="''"
        noOptions="''"
        :placeholder="$t('selectProducer')"
        :selectLabel="$t('pressEnterToSelect')"
        :deselectLabel="$t('pressEnterToRemove')"
        :options="producers"
        :multiple="false"
        :searchable="true"
        :loading="isLoading"
        :internal-search="true"
        :clear-on-select="false"
        :close-on-select="true"
        :show-no-results="false"
        :hide-selected="false"
        :disabled="disabled"
        @close="markProducerAsDirty"
      />
    </div>
    <div v-if="producerTouched && !producer" class="errors help-block">
      <span class="custom-error">{{ $t('producerIsNotSelected') }}</span>
    </div>
  </div>
</template>

<script>
import {APIService} from "../../../../services/api";

export default {
  name: 'ProductProducer',
  props: {
    filterData: {
      type: Object,
      default: () => {},
    },
    dataIndex: {
      type: Number,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      producer: null,
      producerTag: null,
      producerTouched: false,
      isLoading: false,
      producers: [],
    }
  },
  async beforeMount() {
    try {
      this.isLoading = true;
      await this.setProducerTag();
      this.producers = await APIService.get(`tag-item?sort=id,ASC&join=tag&filter=tag.id||eq||${this.producerTag.id}`);
      if (this.filterData.items.length > 0) {
        this.producer = this.filterData.items[0];
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    markProducerAsDirty() {
      this.producerTouched = true;
    },
    async setProducerTag() {
      if (this.filterData.tag) {
        this.producerTag = this.filterData.tag;
      }
      if (!this.producerTag) {
        const [producerTag] = await APIService.get('tag?filter=token||eq||producer');
        this.producerTag = producerTag;
      }
    },
  },
  watch: {
    producer: {
      handler: function (val) {
        const updatedValue = {
          tag: this.producerTag,
          items: [val],
        };
        this.$emit('updateModel', {
          index: this.dataIndex,
          updatedPart: updatedValue,
        });
      },
      deep: true,
    },
  },
}
</script>
