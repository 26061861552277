import { validators } from "vue-form-generator";
import fieldProductTagItemPrices from "../components/productPriceTagItems/FieldProductTagItemPrices";
import fieldProductTagItemPricesTranslated from "../components/productPriceTagItems/FieldProductTagItemPricesTranslated";
import { productPriceTagItemsValidator } from "../validations/productPriceTagItems";
import i18n from "@/plugins/i18n";
import Vue from "vue";

Vue.component('fieldProductTagItemPrices', fieldProductTagItemPrices);
Vue.component('fieldProductTagItemPricesTranslated', fieldProductTagItemPricesTranslated);

export const schema = {
  fields: [
    {
      type: "select",
      label: "status",
      model: "status",
      required: true,
      validator: [
        validators.required.locale({
          fieldIsRequired: i18n.t("statusRequired"),
        }),
      ],
      selectOptions: {
        noneSelectedText: i18n.t("chooseStatus"),
      },
      values: [
        { name: i18n.t("Active"), id: 1 },
        { name: i18n.t("Hidden"), id: 0 },
      ],
    },
    {
      type: "productTagItemPrices",
      translationType: "productTagItemPricesTranslated",
      label: "tagPriceItems",
      model: "tagPriceItems",
      styleClasses: "dummy-error",
      default: [{
        price: null,
        sku: "",
        tagItem: {
          value: "",
          tag: null,
          status: 1,
        },
      }],
      validator: [
        productPriceTagItemsValidator,
      ],
    },
    {
      type: "input",
      inputType: "text",
      label: "url",
      model: "url",
      placeholder: "url",
      max: 255,
      validator: [
        validators.string.locale({
          textTooBig: i18n.t("urlMustBeLess{1}")
        }),
      ]
    },
    {
      type: "textArea",
      rows: 3,
      label: "metaTitle",
      model: "metaTitle",
      required: false,
      placeholder: "metaTitle",
    },
    {
      type: "textArea",
      rows: 3,
      label: "metaKeywords",
      model: "metaKeywords",
      required: false,
      placeholder: "metaKeywords",
    },
  ]
};
