<template>
    <div>
        <div v-if="value">
        <b-row>
            <b-col
                v-if="value.length > 0"
                sm="9"
                md="10"
                lg="10"
            >
                <b-row>
                    <b-col sm="6" lg="3">
                        <label>{{ $t('description') }}</label>
                    </b-col>
                    <b-col sm="6" lg="3">
                        <label>{{ $t('price') }}</label>
                    </b-col>
                    <b-col sm="6" lg="3">
                        <label>{{ $t('discount') }}</label>
                    </b-col>
                    <b-col sm="6" lg="3">
                        <label>{{ $t('article') }}</label>
                    </b-col>
                </b-row>
            </b-col>
            <b-col
                sm="3"
                md="2"
                lg="2"
            >
                &nbsp;
            </b-col>
        </b-row>
        
        <b-row v-for="(priceTag, index) in value" :key="priceTag.id">
            <b-col
                sm="9"
                md="10"
                lg="10"
            >
                <price-tag-item-translated
                    ref="price-tag-item-translated"
                    :value="priceTag"
                    :index="index"
                    @updateTranslations="updateTranslations"
                />
            </b-col>
            <b-col
                sm="3"
                md="2"
                lg="2"
            >
                &nbsp;
            </b-col>
        </b-row>
    </div>
    </div>   
</template>
  
<script>
    import Vue from 'vue';
    import { abstractField } from 'vue-form-generator';
    import { productPricesTagItemEventBus } from './productPricesTagItemEventBus';
    import PriceTagItem from './FieldPriceTagItem';
    import PriceTagItemTranslated from './FieldPriceTagItemTranslated';

    export default {
        mixins: [abstractField],
        components: {
            PriceTagItem,
            PriceTagItemTranslated,
        },
        created () {
            productPricesTagItemEventBus.$on('added-item', () => {
                this.addItem();
            });
            productPricesTagItemEventBus.$on('remove-item', (eventData) => {
                this.removeItem(eventData.index);
            });
            productPricesTagItemEventBus.$on('updated-item', (eventData) => {
                const { index, data } = eventData;
                Vue.set(this.value, index, data);
            });
        },
        methods: {
            addItem() {
                this.value.push(this.schema.default[0]);
            },
            validate() {
                this.clearValidationErrors();
                const value = this.value;
                const validation = this.schema.validator[0];
                for (const ref of this.$refs['price-tag-item-translated']) {
                    ref.touch();
                }
                return validation(value);
            },
            updateTranslations(eventData) {
                productPricesTagItemEventBus.$emit('updated-translations', eventData);
            },
            removeItem(index) {
                this.value.splice(index, 1);
            }
        }
    };
</script>