<template>
  <div>
    <div v-for="(attributeData, index) in field" :key="index">
      <div v-if="attributeData.productAttribute.type === 1">
        <label>{{ attributeData.productAttribute.name }}</label>
        <b-form-input 
          @change="updateModel" 
          v-model="attributeData.value"
          :disabled="disabled"
          :placeholder="attributeData.productAttribute.name" 
          max="255"
          required
        />
      </div>
      <div v-if="attributeData.productAttribute.type === 2">
        <label>{{ attributeData.productAttribute.name }}</label>
        <b-form-input 
          @change="updateModel" 
          v-model="attributeData.value"
          :disabled="disabled"
          :placeholder="attributeData.productAttribute.name" 
          type="number" 
          min="0"
          required
        />
      </div>
      <div v-if="attributeData.productAttribute.type === 3">
        <label :for="attributeData.productAttribute.code">{{ attributeData.productAttribute.name }}</label>
        <b-checkbox
          @change="value => updateCheckBoxModel(index, value)"
          :checked="attributeData.value === '1'"
          :disabled="disabled"
          :placeholder="attributeData.productAttribute.name"
          :id="attributeData.productAttribute.code"
          class="pull-left"
          required
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { abstractField } from 'vue-form-generator'

  export default {
    mixins: [abstractField],
    data () {
      return {
        field: [],
      }
    },
    async beforeMount() {
      const field = [];
      if (!this.value) {
        for (const attribute of this.schema.attributes) {
          field.push({
            value: attribute.type === 3 ? false : null,
            productAttribute: attribute,
          });
        }
        this.field = field;
      } else {
        this.field = this.value;
      }
    },
    methods: {
      updateModel() {
        this.value = this.field;
      },
      updateCheckBoxModel(index, value) {
        this.field[index].value = value ? '1' : '0'
        this.updateModel();
      }
    }
  }
</script>
